@import '../../../../scss/theme-bootstrap';

.gnav-tout {
  font-family: $font--text;
  font-size: 15px;
  @include breakpoint($landscape-up) {
    display: flex;
    align-items: flex-end;
  }
  &__inner {
    display: flex;
    gap: 5px;
    height: 120px;
    background-color: $color-lighter-gray-2;
    border-bottom: 1px solid $color-primary-400;
    @include breakpoint($landscape-up) {
      width: 100%;
      height: 100%;
      border-bottom: none;
      align-items: flex-end;
      gap: 5px;
      padding-#{$ldirection}: 20px;
    }
  }
  &__image {
    width: 120px;
    display: flex;
    align-items: center;
    @include breakpoint($landscape-up) {
      width: 46%;
    }
    .mantle-media-asset {
      height: auto;
    }
    img {
      max-height: 120px;
      padding: 10px 20px;
      @include breakpoint($landscape-up) {
        max-height: unset;
        height: auto;
        width: auto;
        max-width: 130px;
        padding: 0;
      }
    }
    picture {
      @include breakpoint($landscape-up) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &__description {
    color: $color-black;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint($landscape-up) {
      width: 54%;
    }
  }
  &__headline {
    color: $color-black;
    font-family: $font--heading;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    &.chat {
      display: flex;
      width: max-content;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
  &__chat-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
  }
  &__copy {
    font-size: 12px;
    line-height: 1.2;
    p {
      margin: 0;
      line-height: 1.2;
    }
  }
}
